import { Component, componentService, services } from '@contorion/core';
import {BillieWidgetContainerV2Component} from "./components/billie-widget-container-v2.component";

services.register('payment', () => {
    return import (
        /* webpackChunkName: "payment-service" */
        './services/payment.service')
        .then(({ paymentService }) => {
            return paymentService;
        });
});

services.register('billpay', () => {
    return import (
        /* webpackChunkName: "billpay-service" */
        './services/billpay.service')
        .then(({ billpayService }) => {
            return billpayService;
        });
});

services.register('address', () => {
    return import (
        /* webpackChunkName: "address-service" */
        '@contorion/modules/address/services/address.service')
        .then(({ addressService }) => {
            return addressService;
        });
});

services.register('existing-address', () => {
    return import (
        /* webpackChunkName: "address-service" */
        './services/existing-address-display.service')
        .then(({ existingAddressDisplayService }) => {
            return existingAddressDisplayService;
        });
});

/* BOSS-16636 clean up after Accordion Checkout AB test START */
services.register('checkout-steps', () => {
    return import (
        /* webpackChunkName: "checkout-steps" */
        './services/checkout-steps.service')
        .then(({ checkoutStepsService }) => {
            return checkoutStepsService;
        });
});
/* BOSS-16636 clean up after Accordion Checkout AB test END */

componentService.register('.js-checkout', (element: Element) => {
    import (
        /* webpackChunkName: "checkout" */
        './components/checkout.component'
        )
        .then(({ CheckoutComponent }) => {
            <Component> CheckoutComponent.loadByElement(element);
        });
});

/* WIP: This will replace checkout component above after refactoring */
componentService.register('.js-checkout-v2', (element: Element) => {
    import (
        /* webpackChunkName: "checkout-v2" */
        './components/checkout-v2.component'
        )
        .then(({ CheckoutV2Component }) => {
            <Component> CheckoutV2Component.loadByElement(element);
        });
});

componentService.register('.existing-addresses-list', (element: Element) => {
    import (
        /* webpackChunkName: "existing-address-display" */
        './components/existing-address-display.component'
        )
        .then(({ ExistingAddressDisplayComponent }) => {
            <Component> ExistingAddressDisplayComponent.loadByElement(element);
        });
});

componentService.register('.js-phone-input-haulier-item', (element: Element) => {
    import (
        /* webpackChunkName: "phone-input-haulier-item" */
        './components/phone-input-haulier-item.component'
        )
        .then(({ PhoneInputHaulierItemComponent }) => {
            <Component> PhoneInputHaulierItemComponent.loadByElement(element);
        });
});

componentService.register('.js-payment', (element: Element) => {
    import (
        /* webpackChunkName: "payment" */
        './components/payment.component'
        )
        .then(({ PaymentComponent }) => {
            <Component> PaymentComponent.loadByElement(element);
        });
});

componentService.register('.js-payment-v2', (element: Element) => {
    import (
        /* webpackChunkName: "payment-v2" */
        './components/payment-v2.component'
        )
        .then(({ PaymentV2Component }) => {
            <Component> PaymentV2Component.loadByElement(element);
        });
});

componentService.register('.js-payment-data-management', (element: Element) => {
    import (
        /* webpackChunkName: "payment-data-management" */
        './components/payment-data-management.component'
        )
        .then(({ PaymentDataManagementComponent }) => {
            <Component> PaymentDataManagementComponent.loadByElement(element);
        });
});

componentService.register('.js-payment-default-method', (element: Element) => {
    import (
        /* webpackChunkName: "payment-default-method" */
        './components/payment-default-method.component'
        )
        .then(({ PaymentDefaultMethodComponent }) => {
            <Component> PaymentDefaultMethodComponent.loadByElement(element);
        });
});

componentService.register('.js-payment-details', (element: Element) => {
    import (
        /* webpackChunkName: "payment-details" */
        './components/payment-details.component'
        )
        .then(({ PaymentDetailsComponent }) => {
            <Component> PaymentDetailsComponent.loadByElement(element);
        });
});

componentService.register('.js-payment-details-v2', (element: Element) => {
    import (
        /* webpackChunkName: "payment-details-v2" */
        './components/payment-details-v2.component'
        )
        .then(({ PaymentDetailsV2Component }) => {
            <Component> PaymentDetailsV2Component.loadByElement(element);
        });
});

componentService.register('.js-billpay-container', (element: Element) => {
    import (
        /* webpackChunkName: "billpay-container" */
        './components/billpay-container.component'
        )
        .then(({ BillpayContainerComponent }) => {
            <Component> BillpayContainerComponent.loadByElement(element);
        });
});

componentService.register('.js-checkout-address', (element: Element) => {
    import (
        /* webpackChunkName: "checkout-address" */
        './components/checkout-address.component'
        )
        .then(({ CheckoutAddressComponent }) => {
            <Component> CheckoutAddressComponent.loadByElement(element);
        });
});

componentService.register('.js-payment-details-summary', (element: Element) => {
    import (
        /* webpackChunkName: "payment-details-summary" */
        './components/payment-details-summary.component'
        )
        .then(({ PaymentDetailsSummaryComponent }) => {
            <Component> PaymentDetailsSummaryComponent.loadByElement(element);
        });
});

componentService.register('.js-steps-accordion', (element: Element) => {
    import (
        /* webpackChunkName: "steps-accordion" */
        './components/steps-accordion.component'
        )
        .then(({ StepsAccordionComponent }) => {
            <Component> StepsAccordionComponent.loadByElement(element);
        });
});

componentService.register('.js-step', (element: Element) => {
    import (
        /* webpackChunkName: "step" */
        './components/step.component'
        )
        .then(({ StepComponent }) => {
            <Component> StepComponent.loadByElement(element);
        });
});

componentService.register('.js-step-accordion', (element: Element) => {
    import (
        /* webpackChunkName: "step-accordion" */
        './components/step-accordion.component'
        )
        .then(({ StepAccordionComponent }) => {
            <Component> StepAccordionComponent.loadByElement(element);
        });
});

componentService.register('.js-accordion-checkout', (element: Element) => {
    import (
        /* webpackChunkName: "accordion-checkout" */
        './components/accordion-checkout.component'
        )
        .then(({ AccordionCheckoutComponent }) => {
            <Component> AccordionCheckoutComponent.loadByElement(element);
        });
});

componentService.register('.js-accordion-checkout-v2', (element: Element) => {
    import (
        /* webpackChunkName: "accordion-checkout" */
        './components/accordion-checkout-v2.component'
        )
        .then(({ AccordionCheckoutV2Component }) => {
            <Component> AccordionCheckoutV2Component.loadByElement(element);
        });
});

componentService.register('.js-shipment', (element: Element) => {
    import (
        /* webpackChunkName: "shipment" */
        './components/shipment.component'
        )
        .then(({ ShipmentComponent }) => {
            <Component> ShipmentComponent.loadByElement(element);
        });
});

componentService.register('.js-shipment-v2', (element: Element) => {
    import (
        /* webpackChunkName: "shipment-v2" */
        './components/shipment-v2.component'
        )
        .then(({ ShipmentV2Component }) => {
            <Component> ShipmentV2Component.loadByElement(element);
        });
});

componentService.register('.js-shipment-option', (element: Element) => {
    import (
        /* webpackChunkName: "shipment-option" */
        './components/shipment-option.component'
        )
        .then(({ ShipmentOptionComponent }) => {
            <Component> ShipmentOptionComponent.loadByElement(element);
        });
});

componentService.register('.js-shipment-group', (element: Element) => {
    import (
        /* webpackChunkName: "shipment-group" */
        './components/shipment-group.component'
        )
        .then(({ ShipmentGroupComponent }) => {
            <Component> ShipmentGroupComponent.loadByElement(element);
        });
});

componentService.register('.js-shipment-group-v2', (element: Element) => {
    import (
        /* webpackChunkName: "shipment-group-v2" */
        './components/shipment-group-v2.component'
        )
        .then(({ ShipmentGroupV2Component }) => {
            <Component> ShipmentGroupV2Component.loadByElement(element);
        });
});

componentService.register('.js-summary-prices', (element: Element) => {
    import (
        /* webpackChunkName: "summary-prices" */
        './components/summary-prices.component'
        )
        .then(({ SummaryPricesComponent }) => {
            <Component> SummaryPricesComponent.loadByElement(element);
        });
});

componentService.register('.js-summary-prices-v2', (element: Element) => {
    import (
        /* webpackChunkName: "summary-prices-v2" */
        './components/summary-prices-v2.component'
        )
        .then(({ SummaryPricesV2Component }) => {
            <Component> SummaryPricesV2Component.loadByElement(element);
        });
});

componentService.register('.js-klarna-payment-container', (element: Element) => {
    import (
        /* webpackChunkName: "klarna-payment-container" */
        './components/klarna-payment-container.component'
        )
        .then(({ KlarnaPaymentContainerComponent }) => {
            <Component> KlarnaPaymentContainerComponent.loadByElement(element);
        });
});

componentService.register('.js-klarna-payment-container-v2', (element: Element) => {
    import (
        /* webpackChunkName: "klarna-payment-container-v2" */
        './components/klarna-payment-container-v2.component'
        )
        .then(({ KlarnaPaymentContainerV2Component }) => {
            <Component> KlarnaPaymentContainerV2Component.loadByElement(element);
        });
});

componentService.register('.js-billie-widget-container', (element: Element) => {
    import (
        /* webpackChunkName: "billie-widget-container" */
        './components/billie-widget-container.component'
        )
        .then(({ BillieWidgetContainerComponent }) => {
            <Component> BillieWidgetContainerComponent.loadByElement(element);
        });
});

componentService.register('.js-billie-widget-container-v2', (element: Element) => {
    import (
        /* webpackChunkName: "billie-widget-container-v2" */
        './components/billie-widget-container-v2.component'
        )
        .then(async ({ BillieWidgetContainerV2Component }) => {
            let billieWidget = <BillieWidgetContainerV2Component> BillieWidgetContainerV2Component.loadByElement(element);
            await billieWidget.init();
        });
});

componentService.register('.js-buyer-reference', (element: Element) => {
    import (
        /* webpackChunkName: "buyer-reference" */
        './components/buyer-reference.component')
        .then(({ BuyerReferenceComponent }) => {
            <Component> BuyerReferenceComponent.loadByElement(element);
        });
});

componentService.register('.js-public-institution-form', (element: Element) => {
    import (
        /* webpackChunkName: "public-institution-form" */
        './components/public-institution-form.component')
        .then(({ PublicInstitutionFormComponent }) => {
            <Component> PublicInstitutionFormComponent.loadByElement(element);
        });
});

componentService.register('.js-addresses-widget', (element: Element) => {
    import (
        /* webpackChunkName: "address-widget" */
        './components/summary-widget-components/address-widget.component'
        )
        .then(({ AddressWidgetComponent }) => {
            <Component> AddressWidgetComponent.loadByElement(element);
        });
});

componentService.register('.js-payment-widget', (element: Element) => {
    import (
        /* webpackChunkName: "payment-widget" */
        './components/summary-widget-components/payment-widget.component'
        )
        .then(({ PaymentWidgetComponent }) => {
            <Component> PaymentWidgetComponent.loadByElement(element);
        });
});

services.register('shipment-option', () => {
    return import (
        /* webpackChunkName: "shipment-options-service" */
        './services/shipment-option.service')
        .then(({ shipmentOptionService }) => {
            return shipmentOptionService;
        });
});

services.register('checkout-service', () => {
    return import (
        /* webpackChunkName: "shipment-options-service" */
        './services/checkout.service')
        .then(({ checkoutService }) => {
            return checkoutService;
        });
});

services.register('checkout-v2-service', () => {
    return import (
        /* webpackChunkName: "shipment-options-service" */
        './services/checkout-v2.service')
        .then(({ checkoutV2Service }) => {
            return checkoutV2Service;
        });
});

services.register('klarna', () => {
    return import (
        /* webpackChunkName: "klarna-service" */
        '@contorion/modules/payment/services/klarna.service')
        .then(({ klarnaService }) => {
            return klarnaService;
        });
});

services.register('klarna-v2', () => {
    return import (
        /* webpackChunkName: "klarna-v2-service" */
        '@contorion/modules/payment/services/klarna-v2.service')
        .then(({ klarnaV2Service }) => {
            return klarnaV2Service;
        });
});

services.register('billie', () => {
    return import (
        /* webpackChunkName: "billie-service" */
        './services/billie.service')
        .then(({ billieService }) => {
            return billieService;
        });
});

services.register('buyer-reference', () => {
    return import (
        /* webpackChunkName: "buyer-reference-service" */
        './services/buyer-reference.service')
        .then(({ buyerReferenceService }) => {
            return buyerReferenceService;
        });
});

services.register('checkout-logger', () => {
    return import (
        /* webpackChunkName: "checkout-logger" */
        './services/checkout-logger.service')
        .then(({ checkoutLoggerService }) => {
            return checkoutLoggerService;
        });
});

componentService.register('.js-always-at-address', (element: Element) => {
    import (
        /* webpackChunkName: "always-at-address" */
        './components/always-at-address.component'
        )
        .then(({ AlwaysAtAddressComponent }) => {
            <Component> AlwaysAtAddressComponent.loadByElement(element);
        });
});

componentService.register('.js-buyer-detail', (element: Element) => {
    import (
        /* webpackChunkName: "buyer-detail" */
        './components/buyer-detail-it.component'
        )
        .then(({ BuyerDetailITComponent }) => {
            <Component> BuyerDetailITComponent.loadByElement(element);
        });
});

componentService.register('.js-buyer-detail-b2c', (element: Element) => {
    import (
        /* webpackChunkName: "buyer-detail-b2c" */
        './components/buyer-detail-b2c-it.component'
        )
        .then(({ BuyerDetailB2CITComponent }) => {
            <Component> BuyerDetailB2CITComponent.loadByElement(element);
        });
});

componentService.register('.js-accordion-checkout-header', (element: Element) => {
    import (
        /* webpackChunkName: "accordion-checkout-header" */
        './components/accordion-checkout-header-v2.component'
        )
        .then(({ AccordionCheckoutHeaderV2Component }) => {
            <Component> AccordionCheckoutHeaderV2Component.loadByElement(element);
        });
});

services.register('stripe', () => {
    return import (
        /* webpackChunkName: "stripe" */
        './services/stripe.service')
        .then(({ stripService }) => {
            return stripService;
        });
});

componentService.register('.js-stripe-eps', (element: Element) => {
    import (
        /* webpackChunkName: "stripe-eps" */
        './components/stripe-eps.component'
        )
        .then(({ StripeEpsComponent }) => {
            <Component> StripeEpsComponent.loadByElement(element);
        });
});

componentService.register('.js-paypal-express-checkout', (element: Element) => {
    import (
        /* webpackChunkName: "paypal-express-checkout" */
        './components/paypal-express-checkout.component')
        .then(({PaypalExpressCheckoutComponent}) => {
            <Component>PaypalExpressCheckoutComponent.loadByElement(element);
        });
});
